/* Portfolio Container */
.portfolio {
  padding: 2rem;
  color: #333;
  text-align: center;
}

/* Header Section */
.portfolio-header span:first-child {
  color: var(--black);
	font-size: 2.5rem;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.portfolio-header span:last-child {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--orange);
  margin-bottom: 2rem;
  display: block;
}

/* Swiper Slider */
.portfolio-slider {
  margin-top: 2rem;
  padding-bottom: 2rem;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.swiper-slide img:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

/* Pagination */
.swiper-pagination-bullet {
  background:var(--orange);
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.swiper-pagination-bullet-active {
  background: #0056b3;
  opacity: 1;
}

/* Responsive Breakpoints */
@media (max-width: 768px) {
  .portfolio {
    padding: 1rem;
  }

  .portfolio-header span:first-child {
    font-size: 1.5rem;
  }

  .portfolio-header span:last-child {
    font-size: 1.8rem;
  }

  .swiper-slide img {
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .portfolio-header span:first-child {
    font-size: 1.2rem;
  }

  .portfolio-header span:last-child {
    font-size: 1.5rem;
  }

  .swiper-slide img {
    max-width: 300px;
    width: 100%;
  }
}

.portfolio-slider {
  transition: all 0.3s ease;
}

.portfolio-slider:hover {
  cursor: pointer;  /* Change cursor to pointer to indicate interactivity */
}

.portfolio-slider .swiper-wrapper {
  pointer-events: auto; /* Enable interaction with the slides when hovering */
}

/* Stop autoplay on hover */
.portfolio-slider:hover .swiper-container {
  animation-play-state: paused;
}
