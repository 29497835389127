:root {
	--yellow: #f5c32c;
	--orange: #fca61f;
	--black: #242d49;
	--gray: #788097;
	--blueCard: #ddf8fe;
	--purple: rgb(238 210 255);
	--boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
	--orangeCard: rgba(252, 166, 31, 0.45);
	--smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}
/* @tailwind base;
@tailwind components;
@tailwind utilities; */


.App {
	padding: 0.5rem 3.5rem;
	overflow: hidden;
	color: var(--black);
}

.button {
	background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
	box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
	border-radius: 34px;
	border: none;
	color: white;
	font-size: 16px;
	padding: 11px 26px 11px 26px;
}

.button:hover {
	background: white;
	cursor: pointer;
	border: 1px solid var(--orange);
	color: var(--orange);
}
@media screen and (max-width: 480px) {
	.App {
		padding: 0.5rem 1rem;
	}
}
